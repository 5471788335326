import * as React from "react";
import Layout from "./../../../components/fr/Layout";
import DondiWhite from "./../../../nav/fr/Artistes/DondiWhite";
import { Helmet } from "react-helmet";

const DondiWhitePage = () => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>DONDI WHITE | GHOST GALERIE</title>
        <meta
          name="description"
          content="GHOST galerie, Marseille. New York City : Post Graffiti • Old School | 80's & 90's"
        />
        <meta name="keywords" content="ghostgalerie,ghostgallery,ghostgalerieexposition,ghostgalerieexhibition,ghostparis,ghostmarseille,ghostartiste,ghostartists,dondiwhiteretrospectiveexhibition,dondiwhiteexposition,dondiwhitegallery,dondiwhitegalerie,dondiwhitepainting,dondiwhitedrawing,dondiwhiteavailable,dondiwhiteexhibition,dondiwhitebook,dondiwhitehistory,dondiwhitehistoire,dondiwhitevirgileabloh,dondiwhite,dondiwhitegraffiti,dondiwhitesubwayart,dondiwhitemarthacooper,dondiwhitemarthacooper,henrychalfant,subwayart,dondiwhitespraypaint,dondiwhiteevent,dondiwhiteestate,dondiwhitearchives,dondiwhitecollection,dondiwhiteart,dondiwhiteparis,dondiwhitemarseille,dondiwhitedessin,stylemastergeneral,dondiwhiteforsale,dondiwhiteresult,dondiwhitechristies,dondiwhitenewyork,dondi,graffitilegend,graffitimaster,graffitiphotos,graffiticulture,graffitinyc,newyorkgraffiti,blackbook,graffitiworld,graffitiartpainting,graffitiart,graffitiaerosol,graffitiposca,dondiwhitegraffiti"/>
      </Helmet>
      <DondiWhite />
    </Layout>
  );
};

export default DondiWhitePage;
