import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
  CarrouselWrapper2,
  LinkWrapper,
  ButtonWrapper,
  TitleWrapper,
  ArtistInfos,
  ImageWrapper,
  MobileProtrait,
  MoreButton,
  More,
} from "./DondiWhite.module.css";
import { withPrefix } from "gatsby"

import Title from "./../../../../components/Title";
import Carousel from 'react-multi-carousel';
import { ListWrapper } from "../../Expositions/Expositions.module.css";
import DondiWhiteAffiche from "../../../../res/Affiche/afficheDondism.png"
import DondiWhitePhoto1 from "../../../../res/Photos site/Dondi White/Dondi White 4.jpg"
import DondiWhitePhoto2 from "../../../../res/Photos site/Dondi White/Dondi White _ Futura.jpg"
import DondiWhitePhoto3 from "../../../../res/Photos site/Dondi White/Dondi White - Sophie Bramly - Ghost galerie.jpg"
import DondiWhitePhoto4 from "../../../../res/Photos site/Dondi White/4.jpg"
import Button from "./../../../../components/Button";
import Dondi1 from "../../../../res/Dondi/exposition/dondi1.jpg"
import Dondi2 from "../../../../res/Dondi/exposition/dondi2.jpg"
import Dondi3 from "../../../../res/Dondi/exposition/dondi3.jpg"
import Pdp from '../../../../res/Dondi/portrait.png'
import { Helmet } from "react-helmet";
import PastPresent1 from "../../../../res/Dondi/past-present-1.jpg"
import PastPresent2 from "../../../../res/Dondi/past-present-2.jpg"
import PastPresent3 from "../../../../res/Dondi/past-present-3.jpeg"
const infos ={
  path: "dondi-white",
  name: "Dondi White",
  description: "DONDI WHITE - dit DONDI - est l’un des pères fondateurs du graffiti « à la New-Yorkaise » des années 1970 - 1980 ; Le King du graffiti figuratif New-Yorkais et du lettrage ; un roi incontesté de mythiques wholes cars - comme le fameux î réalisé en 1980. Il forme le groupe CIA en 1977 et devient rapidement le chef de file du mouvement new yorkais. Son talent graphique lui permet de faire évoluer son esthétisme - passant des rames de métro à la toile pour devenir l’un des artistes majeurs du XXème siècle. Dondi White transpose sur toile les lettres et les icônes symboliques de l’univers urbain dans une approche très intime. Il sait maîtriser à la fois la technique méticuleuse de la bombe aérosol sur différents médiums (murs, trains puis toile) mais aussi le travail précis du dessin.",
  pdp: Pdp,
  alt_pdp: "Photo de profil de Dondi White.",
  photos: [
    { src: PastPresent1, name: "Dondi White" },
    { src: PastPresent2, name: "Dondi White" },
    { src: PastPresent3, name: "Dondi White" },
  ],
  citations: [
    "« I never was a graffiti writer artist even when I was active in the subway yards. I was a subway painter. A subway writer now that I do work on canvas. The work consists of high tech letters with ghetto based images (they are not graffiti paintings). If you must tittle my work it can only up under one title Dondism which is the state of Dondi. The composer of Dondism »",
    "« I never had to compromise myself. It was always me, doing what was comfortable, and relaying the message that I didn’t have to be taught to be creative and do good things. I guess what I’m saying is that I’m here and I want to be heard. I come from the streets, I painted on subways, and I feel that my work is important. I want people to see it - I want to influence others »"
  ]
};

const infos2 = {
  photos: [
    { src: Dondi1, name: "DONDI WHITE", artist:"DONDI WHITE\n(Donald J. White dit) (1961 - 1998)", title:"Naked Death, 1984", desc1: "Peinture aérosol sur toile", desc2 :"Spray paint on canvas", desc3: "179 x 258 cm\n183 x 260 cm (encadrée)", desc4:"70 1/2 x 101 3/8 in.\n72 1/8 x 102 3/8 .in (framed)", desc5:"Signée, datée : \"1984\" et titrée au dos", desc6: "Signed, dated : \"1984\" and titled on the reverse" ,desc7: "- Collection particulière." },
    { src: Dondi2, name: "DONDI WHITE", artist:"DONDI WHITE\n(Donald J. White dit) (1961 - 1998)", title:"Save the worker, 1984", desc1: "Peinture aérosol sur toile", desc2:"Spray paint on canvas", desc3:"179 x 171,5 cm", desc4: "70 1/2 x 67 1/2 in.", desc5:"Signée, datée et titrée au dos", desc6:"Signed, dated and titled on the reverse", desc7:"- Collection particulière." },
    { src: Dondi3, name: "DONDI WHITE", artist:"DONDI WHITE\n(Donald J. White dit) (1961 - 1998)", title:"Save the worker, 1984", desc1: "Peinture aérosol sur toile", desc2:"Spray paint on canvas", desc3:"179 x 171,5 cm", desc4: "70 1/2 x 67 1/2 in.", desc5:"Signée, datée et titrée au dos", desc6:"Signed, dated and titled on the reverse", desc7:"- Collection particulière." },
  ],
};


const exhibitions = [
  {
    date: "Avril 2018",
    title: "DONDISM",
    link: "dondi-white",
    artist: "Dondi White",
    place: "Marseille",
    description:"DONDI WHITE - dit DONDI - est l’un des « pères » fondateurs du graffiti « à la New-Yorkaise » des années 70-80 ; Le King du graffiti figuratif New-Yorkais et du lettrage ; Un roi incontesté de mythiques « wholes cars » - comme le fameux children of the grave réalisé en 1980. Son talent graphique lui permit de faire évoluer son esthétisme - passant des rames de métro à la toile pour devenir un des artistes majeurs du XXe siècle.",
    presentation_photo: DondiWhiteAffiche,
    photos_paths: ["path", "path", "path", "path"],
  },
]

const isBrowser = typeof window !== "undefined"

const DondiWhite = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
      <Helmet>
        {
            isBrowser ? window.onscroll = function(e){
              var sel = document.querySelector('#f')
              var end = document.querySelector('#end').offsetTop - 450;
              if (window.scrollY > end) {
                  sel.style.position = 'absolute';
                  sel.style.top = end.toString()+ "px";
              } else {
                  sel.style.position = 'fixed';
                  sel.style.top = 0;
              }
            } : null
          }
      </Helmet>
      <div className={CardWrapper}>
        <div className={ProfilWrapper} id="f">
          <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
        </div>
        <div className={ArtistDescription}>
          <h1 className={TitleWrapper}>DONDI WHITE <h2 style={{paddingLeft: "16px"}}>(Donal J. White - Américain)</h2></h1>
          <h3 className={NameWrapper} >1961 - 1998</h3>
          <img className={MobileProtrait} src={infos.pdp} alt={infos.alt_pdp} />
          <div className={DescriptionWrapper} dangerouslySetInnerHTML={{__html:infos.description}}></div>
          <br />
          <div  className={Citations}>
            {infos.citations.map((citations) => (
              <div>
                <p style={{fontStyle: "italic", textAlign: "center" }}>{citations}</p>
                <br />
              </div>
            ))}
          </div>
          <br />
          <a href={"/fr/expositions/" + exhibitions[0].link} className={LinkWrapper}>« Dondism » - du 8 avril au 10 mai 2018 - Marseille.</a>
            <ul className={ArtistInfos}>
                           <br />
              <p style={{fontWeight: "bolder"}}>EDUCATION</p>
              <br />
              <p>- High school GED</p>
              <br />
              <br />
              <p style={{fontWeight: "bolder"}}>SOLO EXHIBITIONS</p>
              <br />
              <p style={{fontWeight: "bold"}}>2018</p>
              <p>- Dondism, Retrospective, Ghost Galerie, Marseille, France, April 7th - May 10th (catalogue) </p>
              <br />
              <p style={{fontWeight: "bold"}}>1999</p>
              <p>- The Dondi White Memorial Exhibition, Exhibit 1A Gallery, New York, USA. </p>
              <br />
              <p style={{fontWeight: "bold"}}>1992</p>
              <p>- The Empire Show, Empire gallery, Soho, New York, USA. </p>
              <br />
              <p style={{fontWeight: "bold"}}>1987</p>
              <p>- Matter of Facts, 56 Bleecker Gallery, New York, USA, April 25th - May 23th. </p>
              <br />
              <p style={{fontWeight: "bold"}}>1984</p>
              <p>- Yaki Kornblit Gallery, Amsterdam, The Netherlands.</p>
              <br />
              <p style={{fontWeight: "bold"}}>1983</p>
              <p>- Yaki Kornblit Gallery , Amsterdam, The Netherlands.</p>
              <p>- Fun Gallery, New York, USA.</p>
              <p>- Gallery Art in Progress, Düsseldorf, Germany.</p>
              <p>- 51X Gallery, New York, USA.</p>
              <br />
              <p style={{fontWeight: "bold"}}>1982</p>
              <p>- Fun Gallery, New York, USA.</p>
              <p>- The Ugly Man, Six Gallery, New York, USA.</p>
                            <div className={More} id="more">
              <br />
              <br />
              <p style={{fontWeight: "bolder"}}>GROUP EXHIBITIONS</p>
              <br />
              <p style={{fontWeight: "bold"}}>2022</p>
              <p>- Street Level, Artnet Auctions, New York, NY, USA.</p>
              <p>- New York Street Style : création des années 80 - Ghost Galerie, Paris, juillet - septembre.</p>
              <p>- Accrochage saisonnier, Ghost Galerie, Paris, France, Janvier-Juin</p>
              <br />
              <p style={{fontWeight: "bold"}}>2021</p>
              <p>- Nos Fantômes - exposition inaugurale, Ghost galerie, Paris, France, 10 Septembre - 20 Novembre.</p>
              <br />
              <p style={{fontWeight: "bold"}}>2013</p>
              <p>- Graffiti Art, Museum Tongerlohuys, Roosendaal, Netherlands.</p>
              <p>- Textures : The Written Word in Contemporary Art, ACA Galleries, New York City, USA, May 4th - June 15th.</p>
              <br />
              <p style={{fontWeight: "bold"}}>2012</p>
              <p>- Art & Toys - Collection Selim Varol, Me Collectors Room Berlin, Berlin, Germany, May 26th - October 14th.</p>
              <p>- Graffiti - New York 80's, Galerie Jérôme de Noirmont, Paris, May 27th - July 20th. (catalogue)</p>
              <br />
              <p style={{fontWeight: "bold"}}>2010</p>
              <p>- Graffiti NYC - Artists of the Third Rail, Benrimon Contemporary, New York, USA, July 25th - August 10th.</p>
              <br />
              <p style={{fontWeight: "bold"}}>2008</p>
              <p>- Graffiti Stories: l’Art Modeste sous les Bombes, Musée Paul Valéry en partenariat avec le Musée International des Arts Modestes, Sète, France, June 30th, 2007 - January 13th.</p>
              <br />
              <p style={{fontWeight: "bold"}}>2007</p>
              <p>- "Le Graffiti Historique", Musée Paul Valéry, Sète, France</p>
              <br />
              <p style={{fontWeight: "bold"}}>2006</p>
              <p>- No Sleep ‘till Brooklyn : A powerHouse Hip Hop Retrospective, The powerHouse Arena, Brooklyn, USA, September 5th - November 19th.</p>
              <br />
              <p style={{fontWeight: "bold"}}>2005</p>
              <p>- Frozen Fire, Museum Tongerlohuys, Roosendaal, Netherlands, April 17th - June 27th. </p>
              <br />
              <p style={{fontWeight: "bold"}}>1993</p>
              <p>- Celebrating 15 Years Above Ground, Retrospective, Space Time Light Gallery, New York, USA. (catalogue)</p>
              <br />
              <p style={{fontWeight: "bold"}}>1992</p>
              <p>- New York Graffiti Art : Coming from the Subway (Collection Speerstra), Groninger Museum, Groningen, Netherlands, October 4 - January 10. (catalogue)</p>
              <p>- Industry, Detroit, Michigan, USA. </p>
              <br />
              <p style={{fontWeight: "bold"}}>1991</p>
              <p>- Graffiti Art : Artiste Americans et Français 1981/1991 (Collection Speerstra), Musée des monuments Français Paris. (catalogue)</p>
              <br />
              <p style={{fontWeight: "bold"}}>1988</p>
              <p>- Real Paint, Procter Art Center, New York, USA.</p>
              <br />
              <p style={{fontWeight: "bold"}}>1987</p>
              <p>- Remembrances, Sfax Gallery, New York, USA.</p>
              <p>- 56 Bleecker Gallery, New York, USA.</p>
              <p>- Nassauischer Kunstverein, Wiesbaden, Germany.</p>
              <p>- Helmond Museum, Helmond, Netherlands.</p>
              <p>- Heidelberger Kunstverein, Heidelberger, Germany.</p>
              <br />
              <p style={{fontWeight: "bold"}}>1986</p>
              <p>- Graffiti, Leopold-Hoesch Museum, Germany, March 9th - May 4th. (catalogue)</p>
              <br />
              <p style={{fontWeight: "bold"}}>1985</p>
              <p>- New York Graffiti, Gemeentemuseum Helmond, Netherlands, February 10th - April 14th. (catalogue)</p>
              <p>- Gallozzi Laplaca Gallery, New York, USA.</p>
              <p>- Ronald Feldman Gallery, New York, USA.</p>
              <br />
              <p style={{fontWeight: "bold"}}>1984</p>
              <p>- Artist T-Shirts, Ronald Feldman Fine Arts Inc, New York, USA.</p>
              <p>- Galleria d’Arte Moderna, Bologne, Italie «Arte di Frontiera».</p>
              <p>- Artists from New York in Monte-Carlo, official opening of the gallery, Gallery Willem Speerstra, Monte-Carlo, Monaco, April 5 - June 20.</p>
              <p>- Graffiti, Groninger Museum. Groningen, Netherlands, January 14 - February 26 (catalogue)</p>
              <p>- Zurich Art Fair, Zurich, Switzerland.</p>
              <p>- Cologne 19th International Art Fair, Cologne, Germany, November 15 - 21.</p>
              <p>- Classical American Graffiti Writers and High Graffiti Artists, Galerie Thomas, Munich, Germany, February 6 - April 5. (catalogue).</p>
              <br />
              <p style={{fontWeight: "bold"}}>1983</p>
              <p>- New York Graffiti, Museum Boijmans-van Beuningen, Rotterdam, The Netherlands, October 22th - December 4th.</p>
              <p>- 121 Art Gallery, Antwerp, Belgium.</p>
              <p>- Gallery Piranesi, Zurich, Switzerland.</p>
              <p>- Intoxication, Monique Knowlton Gallery, New York, USA.</p>
              <p>- Seibu Gallery, Tokyo, Japan.</p>
              <br />
              <p style={{fontWeight: "bold"}}>1982</p>
              <p>- University of California, Santa Cruz, USA.</p>
              <p>- Burners, Mary Porter Sesnon Gallery, Santa Cruz, USA.</p>
              <p>- 51X Gallery, New York, USA.</p>
              <p>- Nature Morte Gallery, New York, USA.</p>
              <p>- New York City Rap Tour (with Rammellzee, Futura, PHASE2, DONDI), France: “Grande Nuit du Rap”, L'Hippodrome de Pantin; Palace. Paris, November 27, and other dates in Paris, Lyon, Metz, Belfort, Mulhouse, Strasbourg, London and Los Angeles.</p>
              <p>- The Ugly Man, Six Gallery, New York, USA.</p>
              <br />
              <p style={{fontWeight: "bold"}}>1981</p>
              <p>- New York/New Wave’, PS 1, Queens, New York, USA.</p>
              <p>- Beyond Words”, Mudd Club, New York, USA.</p>
              <p>- The Kitchen, New York, USA.</p>
              <p>- Club 57, New York, USA.</p>
              <br />
              <p style={{fontWeight: "bold"}}>1980</p>
              <p>- Esses Studio, founded by Dondi, New York, USA.</p>
              <br />
              <p style={{fontWeight: "bolder"}}>PRIVATE COLLECTIONS</p>
              <br />
              <p>- Museum Boymans van Bueningen, Holland.</p>
              <p>- Groninger Museum, Holland.</p>
              <p>- Louisiana Museum, Denmark.</p>
              <p>- Stedelijk Museum, Holland.</p>
              <p>- Rijks museum, Holland.</p>
              <p>- Stedeljik Museum, Holland.</p>
              <p>- Helmond Museum, Germany.</p>
              <p>- Leopold - Hoesch Museum, Germany.</p>
              <p>- Heidelberg Kunstverein, Germany.</p>
              <p>- Musée National des monuments Français, France.</p>
              <p>- Groninger Museum, Holland.</p>
              <p>- Roermond Museum, Holland.</p>
              <p id="end">- Museum der Stadt Ratingen, Germany.</p>
            </div>
            </ul>
            <button className={MoreButton} id="moreBtn" onClick={() => {
                 var sel = document.querySelector('#more');
                 var selBtn = document.querySelector('#moreBtn');
                 console.log("in there");

                
                  if (sel.style.display === "none") {
                    sel.style.display = "inline";
                    selBtn.innerHTML = "Read more";
                  } else {
                    sel.style.display = "none";
                    selBtn.innerHTML = "Read less";
                }
                if (sel.style.display === "none") {
                  sel.style.display = "inline";
                  selBtn.innerHTML = "Read more";
                } else {
                  sel.style.display = "none";
                  selBtn.innerHTML = "Read less";
              }
              if (sel.style.display === "none") {
                sel.style.display = "inline";
                selBtn.innerHTML = "Read more";
              } else {
                sel.style.display = "none";
                selBtn.innerHTML = "Read less";
            }
                 
              }}
               >Read more</button>
        </div>
      </div>
      <div className={PhotosWrapper}>
        <Title title={"Past or Present"} />
        <div className={CarrouselWrapper2}>
          {/* <Carrousel images={infos.photos} />
           */}
           <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
        </div>
      </div>
      <div className={ButtonWrapper}>
            <Button
              alt=""
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.location.href = "/fr/contactez-nous";
                }
              }}
            >
              Contactez-nous
            </Button>
        </div>
    </div>
  );
};

export default DondiWhite;