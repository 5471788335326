// extracted by mini-css-extract-plugin
export var ArtistDescription = "DondiWhite-module--ArtistDescription--P2XST";
export var ArtistInfos = "DondiWhite-module--ArtistInfos---XxKo";
export var ButtonWrapper = "DondiWhite-module--ButtonWrapper --mJaLB";
export var CardWrapper = "DondiWhite-module--CardWrapper--Kr43s";
export var CarrouselWrapper2 = "DondiWhite-module--CarrouselWrapper2--F-FyN";
export var Citations = "DondiWhite-module--Citations--BO7V7";
export var DescriptionWrapper = "DondiWhite-module--DescriptionWrapper--jJC6o";
export var ImageWrapper = "DondiWhite-module--ImageWrapper--28HHh";
export var LinkWrapper = "DondiWhite-module--LinkWrapper--7f3TJ";
export var MobileProtrait = "DondiWhite-module--MobileProtrait--AZn6F";
export var More = "DondiWhite-module--More--VbNPr";
export var MoreButton = "DondiWhite-module--MoreButton--sXvvl";
export var NameWrapper = "DondiWhite-module--NameWrapper---i6Oa";
export var PdpWrapper = "DondiWhite-module--PdpWrapper--YVQZg";
export var PhotosWrapper = "DondiWhite-module--PhotosWrapper--8uliC";
export var ProfilWrapper = "DondiWhite-module--ProfilWrapper--t1OWK";
export var TitleWrapper = "DondiWhite-module--TitleWrapper--rElWQ";
export var Wrapper = "DondiWhite-module--Wrapper--JLMnv";